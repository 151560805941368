import { Typography, Grid } from '@mui/material';
import React from 'react';

function Header({title}) {
  return (
    <Grid container alignItems="center" sx={{margin:'0px',marginLeft:'0px'}} >
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom >
        {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default React.memo(Header);
