import React, { useState, useEffect } from "react";
import { DataGrid, GridPagination, GridSearchIcon } from "@mui/x-data-grid";
import ResponsiveDialog from "src/common/DeleteDialogue";
import {
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { IconButton } from "@mui/material";
import Footer from "src/components/Footer";
import { Box } from "@mui/material";
import axios from "axios";
import CommonUserModal from "src/common/commonUserModal";
import { useNavigate } from "react-router";
import Header from "src/common/Header";
import { approveSiteApi, baseUrl, deleteSite, getAllSite } from "src/helpers/Config";
import CommonMeta from "src/common/CommonMeta";
import {
  CustomApprove,
  CustomDelete,
  CustomEdit,
  CustomView,
} from "src/common/Custom_Actions";
import {
  CommonSvg,
  CustomToolbar,
  StyledGridOverlay,
} from "src/common/DataTable_Custom";
import { getDate } from "src/helpers/utils";
import Custom_BreadCrumbs from "src/common/Custom_BreadCrumbs";
import { Link } from "react-router-dom";
import Page_Title from "src/common/Page_Title";
import { notify } from "src/helpers/CommonToast";

const GetAll_Sites = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [userDeleteId, setUserDeleteId] = useState();
  const getApiData = `${baseUrl}${getAllSite}`;
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [modalOpen, setModalOpen] = useState();
  const auth = React.useRef(false);
  const token = localStorage.getItem("token") || "";
  const [openDeleteModal, setDeleteModal] = useState();
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const userDeleteApi = `${baseUrl}${deleteSite}`;
  const [loading, setLoading] = useState(true);
  const breadcrumbs = [
    <Link
      key="1"
      color="inherit"
      to="/"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Home
    </Link>,
    <Link
      key="2"
      color="inherit"
      to="/site-management/getAllOnboardedSite"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Sites
    </Link>,
    <Typography key="3" color="text.primary">
      Sites Requested
    </Typography>,
  ];

  const approveSite = (id) =>{
    const formData = new FormData();
    formData.append("site_id",id)
    const apiUrl = `${baseUrl}${approveSiteApi}`
    axios.put(apiUrl, formData,{
      headers:{
        accept: "*/*",
        "Auth-Token": `${token}`,
      }
    }).then((res)=>{
      console.log("Site onboarded succesfully",res?.data)
      if(res?.data?.message !== "Something went wrong."){
        getAllData();
        notify("Site onboarded successfully.","success")
        navigate(`/site-management/getAllOnboardedSite`)
      }
      else{
        notify("Error occured while onboarding site","error")
      }
    }).catch((error)=>{
      console.log("Error occured while onboarding site",error)
      notify("Error occured while onboarding site","error")
    })
  }

  const renderActionsCell = (params) => {
    const user = params.row;
    return (
      <div>
        <CustomApprove
          title={`Onboard Site`}
          action={() => approveSite(user?._id)}
        />
        <CustomDelete
          title={`Delete Site`}
          action={() => deleteUserData(user?._id)}
        />
      </div>
    );
  };

  const deleteUserData = async (id) => {
    setUserDeleteId(id);
    setDeleteModal(true);
  };

  const columns = [
    { field: "index", headerName: "S.no", width: 100 },
    {
      field: "name",
      headerName: "Site Name",
      width: 300,
      editable: false,
    },
    {
      field: "url",
      headerName: "Site Url",
      width: 340,
      editable: false,
    },
    {
      field: "created_at",
      headerName: "Requested Date",
      width: 200,
      editable: false,
      renderCell: (params) => getDate(params?.value),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      editable: false,
      renderCell: renderActionsCell,
    },
  ];

  useEffect(() => {
    if (userData) {
      const indexedUserData = userData.filter((item) => !item?.active).map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      setOriginalData(indexedUserData);
      setFilteredData(indexedUserData);
    }
  }, [userData]);

  const getAllData = async () => {
    await axios
      .get(`${getApiData}`, {
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      })
      .then((response) => {
        console.log("DATA FOR GET SITES", response.data.data);
        const actualData = response.data.data?.reverse();
        setUserData(actualData);
        setIsAuthenticated(false);
      })
      .catch((error) => {
        console.log("Error occured in getting sites", error);
        setIsAuthenticated(true);
        auth.current = true;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setIsAuth(true);
    }
  }, [isAuthenticated]);

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <CommonSvg />
        <Box sx={{ mt: 1 }}>No Site Found</Box>
      </StyledGridOverlay>
    );
  }

  React.useEffect(() => {
    if (search === "") {
      setFilteredData(originalData);
    } else {
      const newFilteredData = originalData.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(newFilteredData);
    }
  }, [search]);

  return (
    <>
      <section style={{ width: "95%", margin: "auto" }}>
        <CommonUserModal open={modalOpen} setOpen={setModalOpen} data={``} />
        <CommonMeta />
        <Custom_BreadCrumbs data={breadcrumbs} />
        <Page_Title>
          <Header title="Sites Requested" />
        </Page_Title>
        <div>
          <div
            className="input-group"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "15px",
              margin: "10px auto 10px auto",
            }}
          >
            <TextField
              value={search}
              sx={{
                width: 300,
              }}
              label="Search site"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <GridSearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Box
            sx={{
              height: filteredData?.length == 0 ? 400 : "",
              width: "100%",
              margin: "auto",
            }}
          >
            <DataGrid
              sx={{
                backgroundColor: "white",
              }}
              loading={loading}
              slots={{
                toolbar: () => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <CustomToolbar />

                    <div
                      className="custom-toolbar-button"
                      style={{ display: "inline-flex", gap: "10px" }}
                    >
                      <Chip
                        label={`Sites Requested: ${originalData.length}`}
                        size="large"
                        variant="outlined"
                      />
                    </div>
                  </div>
                ),
                noRowsOverlay: CustomNoRowsOverlay,
                footer: () => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                        margin: "auto",
                        padding: "10px",
                        borderTop: "1px solid #e0e0e0",
                        alignItems: "center",
                      }}
                    >
                      <GridPagination />
                    </div>
                  </>
                ),
              }}
              rows={filteredData && filteredData}
              getRowId={(row) => row.index}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              disableDensitySelector
              pageSizeOptions={[25, 30, 50]}
              disableRowSelectionOnClick
            />
          </Box>
        </div>
        <ResponsiveDialog
          openDialogue={openDeleteModal}
          setopenDialogue={setDeleteModal}
          title="site"
          setAnswer={``}
          id={userDeleteId}
          apiUrl={userDeleteApi}
          getAllData={getAllData}
        />
        <Footer />
      </section>
    </>
  );
};
export default React.memo(GetAll_Sites);
