import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { notify } from "src/helpers/CommonToast";
import CommonMeta from "src/common/CommonMeta";
import Header from "src/common/Header";
import {
  baseUrl,
  createManualUser,
  createUser,
  getAllSite,
  updateUser,
} from "src/helpers/Config";
import Custom_BreadCrumbs from "src/common/Custom_BreadCrumbs";
import Ministry from "src/nic_components/common/Ministry";
import Autocomplete from "@mui/material/Autocomplete";

const CreateUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const [loading, setLoading] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const getApiData = `${baseUrl}${getAllSite}`;
  const [siteData, setSiteData] = useState([]);
  const [siteLoading, setSiteLoading] = useState();
  const [field, setField] = useState({
    name: data?.name || "",
    email: data?.email || "",
    password: data?.password || "",
    password_confirmation: data?.password_confirmation || "",
    is_admin: data?.is_admin || 0,
    ministry: data?.ministry || "",
    departments: data?.departments || "",
    site_data: undefined,
    isManual: data?.ismanual ? data?.ismanual  : false,
  });

  const [error, setError] = useState({
    nameError: false,
    emailError: false,
    passwordError: false,
    passwordConfirmationError: false,
    designationError: false,
    departmentError: false,
  });

  const token = localStorage.getItem("token");

  const handleChange = React.useCallback(
    (event) => {
      const { name, value } = event.target;
      setField((prevField) => ({
        ...prevField,
        [name]: value,
      }));

      setError((prevError) => ({
        ...prevError,
        nameError: name === "name" && value.length < 3,
      }));

      setError((prevError) => ({
        ...prevError,
        emailError:
          name === "email" &&
          !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value),
      }));

      if (name === "password") {
        setError((prevError) => ({
          ...prevError,
          passwordError:
            value.length > 0 && (value.length < 6 || value.length > 20),
        }));
      }

      if (name === "password_confirmation") {
        setError((prevError) => ({
          ...prevError,
          passwordConfirmationError: value !== field.password,
        }));
      }
      if (name === "designation") {
        setError((prevError) => ({
          ...prevError,
          designationError: name === "designation" && value.length < 3,
        }));
      }
      if (name === "department") {
        setError((prevError) => ({
          ...prevError,
          departmentError: name === "department" && value.length < 2,
        }));
      }
    },
    [field, error]
  );

  const addUser_Api = React.useMemo(() => {
    return `${baseUrl}${createUser}`;
  }, [baseUrl, createUser]);

  const updateUser_Api = React.useMemo(() => {
    return `${baseUrl}${updateUser}`;
  }, [baseUrl, updateUser]);

  const createManualUserApi = React.useMemo(() => {
    return `${baseUrl}${createManualUser}`;
  }, [baseUrl, createManualUser]);

  const postData = React.useCallback(() => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", field.name);
    formData.append("email", field.email);
    formData.append("department", field.departments);
    formData.append("designation", field.designation);
    formData.append("password", field.password);
    formData.append("password_confirmation", field.password_confirmation);
    if (data?._id) {
      axios
        .put(`${updateUser_Api}${data._id}`, field, {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        })
        .then((res) => {
          notify("User updated successfully.", "success");
          navigate(`/management/getAllUsers`);
        })
        .catch((error) => {
          notify("Failed to update user.", "error");
          setField({
            name: data?.name || "",
            email: data?.email || "",
            password: data?.password || "",
            password_confirmation: data?.password_confirmation || "",
            is_admin: data?.is_admin || 0,
            ministry: data?.ministry || "",
            departments: data?.departments || "",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .post(addUser_Api, formData, {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        })
        .then((res) => {
          if (res?.data?.status) {
            notify("User created successfully.", "success");
            navigate(`/management/getAllUsers`);
          } else {
            const message = res?.data?.error?.email;
            if (message) {
              notify(message?.length && message[0], "error");
            } else {
              notify("Failed to create user.", "error");
            }
          }
        })
        .catch((error) => {
          notify("Failed to create user.", "error");
          setField({
            name: data?.name || "",
            email: data?.email || "",
            password: data?.password || "",
            password_confirmation: data?.password_confirmation || "",
            is_admin: data?.is_admin || 0,
            ministry: data?.ministry || "",
            departments: data?.departments || "",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [field, data, updateUser_Api, addUser_Api, createManualUserApi]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();

      if (
        error.nameError ||
        error.emailError ||
        error.passwordError ||
        error.passwordConfirmationError
      ) {
        alert("Please fill in all fields correctly");
      } else {
        postData();
      }
    },
    [error, postData]
  );

  const showTitle = React.useCallback(() => {
    if (data) {
      return "Update user";
    } else if (!data) {
      return "Add user";
    }
  }, [data, location]);

  const getAllData = React.useCallback(async () => {
    setSiteLoading(true);
    await axios
      .get(`${getApiData}`, {
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      })
      .then((response) => {
        console.log("DATA FOR GET SITES", response.data.data);
        const actualData = response.data.data?.reverse();
        setSiteData(actualData);
      })
      .catch((error) => {
        console.log("Error occured in getting sites", error);
      })
      .finally(() => {
        setSiteLoading(false);
      });
  },[getApiData,token]);

  const breadcrumbs = [
    <Link
      key="1"
      color="inherit"
      to="/dashboards/dashboard"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Home
    </Link>,
    <Link
      key="2"
      color="inherit"
      to="/management/getAllUsers"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Users
    </Link>,
    <Typography key="3" color="text.primary">
      {showTitle()}
    </Typography>,
  ];

  const handleSiteChange = React.useCallback((event, newValue) => {
    const updatedSiteData = newValue.map((option) => ({
      name: option.name,
      url: option.url,
    }));
    setField((prevState) => ({
      ...prevState,
      site_data: updatedSiteData,
    }));
  },[field]);
  
  React.useEffect(() => {
    getAllData();
  }, []);

  console.log("Site Data", siteData);

  console.log("field", field?.isManual);

  return (
    <>
      <section style={{ width: "95%", margin: "auto" }}>
        <CommonMeta />
        <Custom_BreadCrumbs data={breadcrumbs} />
        <PageTitleWrapper>
          <Header title={showTitle()} />
        </PageTitleWrapper>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <Card>
              <CardHeader title={showTitle()} />
              <Divider />
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "48%" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      label="Name"
                      name="name"
                      value={field.name}
                      variant="outlined"
                      // disabled={data?.name ? true : false}
                      onChange={handleChange}
                      error={error.nameError}
                      helperText={
                        error.nameError
                          ? "Name must be at least 3 characters long"
                          : ""
                      }
                      sx={{ flex: "1 1 48%" }}
                    />
                    <TextField
                      label="Email"
                      name="email"
                      value={field.email}
                      disabled={data?.email ? true : false}
                      variant="outlined"
                      onChange={handleChange}
                      error={error.emailError}
                      helperText={
                        error.emailError ? "Invalid email format" : ""
                      }
                      sx={{ flex: "1 1 48%" }}
                    />
                  </div>
                  {!data?._id && (
                    <div>
                      <TextField
                        type="password"
                        label="Password"
                        name="password"
                        value={field.password}
                        variant="outlined"
                        onChange={handleChange}
                        error={error.passwordError}
                        helperText={
                          error.passwordError
                            ? "Password length should be between 6 and 20 characters"
                            : ""
                        }
                        sx={{ flex: "1 1 48%" }}
                      />
                      <TextField
                        type="password"
                        label="Confirm password"
                        name="password_confirmation"
                        value={field.password_confirmation}
                        variant="outlined"
                        onChange={handleChange}
                        error={error.passwordConfirmationError}
                        helperText={
                          error.passwordConfirmationError
                            ? "Password confirmation does not match"
                            : ""
                        }
                        sx={{ flex: "1 1 48%" }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "20px",
                      marginTop: "10px",
                      margin: "10px",
                    }}
                  >
                    <Ministry
                      data={data?.ministry}
                      departmentField={field?.departments}
                      ministryValue={field?.ministry}
                      onChange={handleChange}
                      departmentData={departmentData}
                      setDepartmentData={setDepartmentData}
                    />
                    <FormControlLabel
                      sx={{ width: "20%" }}
                      control={
                        <Checkbox
                          value={field?.isManual}
                          checked={field?.isManual}
                          disabled={data?.name}
                          onChange={(e)=>setField({...field,isManual:e.target.checked})}
                        />
                      }
                      label="Manual User"
                    />
                  </div>
                  {!data && field?.isManual &&  (
                    <div>
                      <Autocomplete
                        fullWidth
                        multiple
                        id="tags-outlined"
                        options={siteData}
                        onChange={handleSiteChange}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            sx={{ width: "98% !important" }}
                            {...params}
                            label="Add Site"
                            placeholder="Sites"
                          />
                        )}
                      />
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{
                        margin: "1rem",
                        backgroundColor: "#4CAF50",
                        color: "white",
                      }}
                      disabled={
                        !field.name ||
                        !field.email ||
                        !field?.ministry ||
                        loading ||
                        (!data?._id && !field.password) ||
                        (!data?._id && !field.password_confirmation) ||
                        error.nameError ||
                        error.emailError ||
                        error.passwordError ||
                        error.passwordConfirmationError
                      }
                    >
                      {loading ? "Please wait..." : showTitle()}
                    </Button>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default React.memo(CreateUser);
