import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import React from "react";
import { getNICMinistry, nicBaseUrl } from "src/helpers/Config";

const Ministry = ({
  data,
  departmentField,
  ministryValue,
  onChange,
  departmentData,
  setDepartmentData,
}) => {
  const [ministryData, setMinistryData] = React.useState([]);

  const getAllMinistry = React.useCallback(() => {
    axios
      .get(`${nicBaseUrl}${getNICMinistry}`)
      .then((res) => {
        console.log("Ministry Response", res.data);
        setMinistryData(res?.data?.data);
      })
      .catch((error) => {
        console.log("Error occured in getting ministry response", error);
      });
  }, [nicBaseUrl, getNICMinistry]);

  const getAllDepartment = React.useCallback(
    (name) => {
      const getDepartmentData = ministryData?.filter(
        (item) => item?.title == name
      );
      if (getDepartmentData[0]?.departments?.length === 0) {
        setDepartmentData([]);
      } else if (getDepartmentData[0]?.departments?.length > 0) {
        setDepartmentData(getDepartmentData[0]?.departments);
      }
    },
    [ministryData]
  );

  React.useEffect(() => {
    if (data && ministryData?.length > 0) {
      getAllDepartment(data);
    }
  }, [data, ministryData]);

  React.useEffect(() => {
    if (departmentField) {
      getAllDepartment(departmentField);
    }
  }, [departmentField]);

  React.useEffect(() => {
    getAllMinistry();
  }, []);

  console.log("getAllMinistry", departmentData);
  return (
    <>
      <FormControl
        style={{
          width: departmentData?.length === 0 ? "100%" : "50%",
        }}
      >
        <InputLabel id="demo-simple-select-label">Ministry</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="ministry"
          name="ministry"
          value={ministryValue}
          label="Ministry"
          onChange={onChange}
        >
          {ministryData?.map((item, index) => {
            return (
              <MenuItem
                value={item.title}
                key={index}
                onClick={() => getAllDepartment(item?.title)}
              >
                {item.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl
        style={{
          width: "50%",
          display: departmentData?.length === 0 && "none",
        }}
      >
        <InputLabel id="demo-simple-select-label">Department</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="departments"
          name="departments"
          value={departmentField}
          label="departments"
          disabled={ministryValue?.length > 0 ? false : true}
          onChange={onChange}
        >
          {departmentData?.map((item, index) => {
            return (
              <MenuItem value={item.title} key={index}>
                {item.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default React.memo(Ministry);
