import { Breadcrumbs } from "@mui/material";
import React from "react";

const Custom_BreadCrumbs = ({data}) => {
  return (
    <>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        style={{ paddingTop: "30px" }}
      >
        {data}
      </Breadcrumbs>
    </>
  );
};

export default React.memo(Custom_BreadCrumbs);
