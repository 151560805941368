import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import Header from "src/common/Header";
import { baseUrl, getSite, getUser, siteQueue } from "src/helpers/Config";
import CommonMeta from "src/common/CommonMeta";
import { loginDateTime } from "src/helpers/utils";
import Custom_BreadCrumbs from "src/common/Custom_BreadCrumbs";
import Page_Title from "src/common/Page_Title";

const Detailed_Site = () => {
  const location = useLocation();
  const slug = location.pathname.replace(`/site-management/siteDetails/`, "");
  const actualSlug = slug.replace("#", "");
  const [data, setData] = React.useState([]);
  const [updatedData, setUpdatedData] = React.useState([]);
  const [queueData, setQueueData] = React.useState([]);
  const token = localStorage.getItem("token") || "";
  const [reportsProcessed, setReportsProcessed] = useState("");
  const [lastReportTime, setLastReportTime] = useState("");
  const [userId, setUserId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [siteModalData, setSiteModalData] = useState([]);
  const [siteUserData, setSiteUserData] = useState("");
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([
    { id: 1, title: "Queue" },
    { id: 2, title: "Download" },
  ]);
  const breadcrumbs = [
    <Link
      key="1"
      color="inherit"
      to="/dashboards/dashboard"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Home
    </Link>,
    <Link
      key="2"
      color="inherit"
      to="/site-management/getAllOnboardedSite"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Sites
    </Link>,
    <Link
      key="2"
      color="inherit"
      to={location?.pathname}
      style={{ textDecoration: "none", color: "gray" }}
    >
      Site Details
    </Link>,
    <Typography key="3" color="text.primary">
      {siteModalData?.name}
    </Typography>,
  ];
  const columns = [
    { field: "id", headerName: <strong>ID</strong>, width: 100 },
    {
      field: "name",
      headerName: <strong>Site Name</strong>,
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "url",
      headerName: <strong>Site Url</strong>,
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "status",
      headerName: <strong>Status</strong>,
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "download",
      headerName: <strong>Download Report</strong>,
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          disabled={params.row.status.toLowerCase() === "pending"}
          onClick={() => handleDownloadReport(params.row)}
        >
          Download
        </Button>
      ),
    },
  ];
  const [activeTab, setActiveTab] = React.useState("");

  const getData = async () => {
    const getQueueSiteApi = `${baseUrl}${siteQueue}${actualSlug}`;
    await axios
      .get(getQueueSiteApi, {
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      })
      .then((res) => {
        console.log("Response of requested site is", res.data?.data);
        setQueueData(res.data?.data);
        setData(res.data?.data);
      })
      .catch((error) => {
        console.log("Error occured in Requesting sites", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownloadReport = (row) => {
    console.log(`Download clicked for ID ${row.id}`);
  };

  const formattedLastLogin = (time) => {
    const dateString = time;
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (queueData) {
      const newData = queueData.map((item, index) => ({
        _id: item?._id,
        id: index + 1,
        name: item.name,
        url: item.url,
        status: item.status,
        download: "Download",
      }));

      setUpdatedData(newData);
    }
  }, [queueData]);

  useEffect(() => {
    const siteByIdApi = `${baseUrl}${getSite}${actualSlug}`;
    axios
      .get(siteByIdApi, {
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      })
      .then((res) => {
        console.log("Unique data", res.data);
        setSiteModalData(res.data?.data?.site);
        setReportsProcessed(res?.data?.data?.countProcessed);
        setLastReportTime(res?.data?.data?.lastProcedDate);
        setSiteId(res.data.data._id);
        setUserId(res.data.data.user_id);
      })
      .catch((error) => {
        console.log("Error in Site Id", error);
      });
  }, []);

  useEffect(() => {
    if (userId) {
      const getUserApi = `${baseUrl}${getUser}${userId}`;
      axios
        .get(getUserApi, {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        })
        .then((res) => {
          console.log("User Data is!@#$%^&*(", res.data.data);
          setSiteUserData(res.data.data);
        })
        .catch((error) => {
          console.log("Error in Site Id", error);
        });
    }
  }, [userId]);

  console.log("QUEUE SITES ARE", queueData);

  return (
    <>
      <section style={{ width: "95%", margin: "auto" }}>
        <CommonMeta />
        <Custom_BreadCrumbs data={breadcrumbs} />
        <Page_Title>
        <Header title="Site Details" />
        </Page_Title>
       <div class="row" style={{ marginTop: "10px" }}>
          <div class="col-sm">
            <div
              style={{
                backgroundColor: "white",
                height: "fit-content",
                boxShadow: "10px 10px 10px 10px #e8eef3",
                borderRadius: "0.25rem",
                padding: "30px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <h5
                  class="card-title f-15 f-w-500 text-darkest-grey mb-0"
                  style={{ fontWeight: "bold" }}
                >
                  WIM User
                </h5>
              </div>
              <div></div>
              <div>
                <p
                  class="card-title f-15 f-w-500 text-darkest-grey mb-0"
                  style={{ color: "gray", fontWeight: 600 }}
                >
                  {siteModalData?.name} ({siteModalData?.url})
                </p>
              </div>
              <div>
                <p
                  class="card-title f-15 f-w-500 text-darkest-grey mb-0"
                  style={{ color: "gray", fontWeight: 600 }}
                >
                  {" "}
                  Onboarded on {formattedLastLogin(siteModalData?.created_at)}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm">
            <div
              style={{
                backgroundColor: "white",
                boxShadow: "10px 10px 10px 10px #e8eef3",
                height: "fit-content",
                borderRadius: "0.25rem",
                padding: "30px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h5
                class="card-title f-13 f-w-500 text-darkest-grey"
                style={{
                  fontWeight: 600,
                  margin: "0px",
                  marginBottom: "0px",
                  fontSize: "1.25rem",
                }}
              >
                Audit
              </h5>
              <div>
                <p
                  style={{
                    fontSize: "15px",
                    marginTop: "9px",
                    fontWeight: 600,
                    color: "gray",
                    margin: "0px",
                  }}
                >
                  {lastReportTime === null
                    ? "No Report Generated"
                    : `Last Generated Report : ${loginDateTime(
                        lastReportTime
                      )}`}
                </p>
              </div>
              <div>
                <p
                  style={{
                    fontSize: "15px",
                    marginTop: "0px",
                    fontWeight: 600,
                    color: "gray",
                    margin: "0px",
                  }}
                >
                  Reports Processed: {reportsProcessed}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row "
          style={{
            height: "50px",
            display: "flex",
            gap: "20px",
            width: "100%",
            margin: "10px auto",
          }}
        >
          <div className="col-sm bg-white d-flex align-items-center justify-content-center">
            <Link
              to={`/site-management/userQueueList/${actualSlug}`}
              className="mb-0 no-underline"
            >
              View queue list
            </Link>{" "}
          </div>
          <div className="col-sm bg-white d-flex align-items-center justify-content-center">
            <Link
              to={`/site-management/downloadReports/${actualSlug}`}
              className="mb-0 no-underline"
            >
              Download Reports
            </Link>{" "}
          </div>
        </div>
        <div className="tab-content mt-2">
          {listData?.map((item, index) => {
            return (
              <>
                <div
                  onClick={() => setActiveTab(item.title)}
                  key={index}
                  style={{
                    display: item.title == activeTab ? "block" : "none",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <div
                    className="tab-pane fade show active"
                    id="content1"
                    style={{
                      background: "#fff",
                      margin: "auto",
                      width: "100%",
                      margin: "auto",
                      borderRadius: "20px",
                      boxShadow: "0 0 52px #3d3d3d1f",
                      border: "1px solid #dcdcdc",
                    }}
                  >
                    <Box
                      sx={{
                        height: 400,
                        backgroundColor: "white",
                      }}
                    >
                      <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rows={updatedData}
                        loading={loading}
                        columns={index == 0 ? columns.slice(0, 4) : columns}
                        getRowId={(updatedData) => updatedData?._id}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        disableColumnMenu
                      />
                    </Box>
                  </div>
                </div>{" "}
              </>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default React.memo(Detailed_Site);
