import { Box, Container, styled } from '@mui/material';
import React from 'react';

const PageTitle = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(4)};
`
);

const PageTitleWrapper = ({ children }) => {
  return (
    <PageTitle className="" style={{padding:'10px 0 0px 0'}}>
      {children}
    </PageTitle>
  );
};

export default React.memo(PageTitleWrapper);
