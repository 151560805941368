import {
  Tooltip,
  Badge,
  tooltipClasses,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { logoImg } from "src/common/Images";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold",
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      "0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

function Logo() {
  const theme = useTheme();

  return (
    <TooltipWrapper title="National Web Quality Index" arrow>
      <LogoWrapper to="/overview">
        <Badge
          sx={{
            ".MuiBadge-badge": {
              fontSize: theme.typography.pxToRem(11),
              right: -2,
              top: 8,
            },
          }}
          overlap="circular"
          color="success"
        >
          <div>
            <img
              src={logoImg}
              alt="Logo"
              loading="lazy"
              style={{ height: "66px" }}
            />
          </div>
        </Badge>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default React.memo(Logo);
