import axios from "axios";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { baseUrl, viewFreeText } from "src/helpers/Config";
import Add_Free_Text from "./Add_Free_Text";

const Update_Free_Text = () => {
  const location = useLocation();
  const slug = location?.pathname?.replace(`/algorithm/updateFreeText`, "");
  const token = localStorage.getItem(`token`);
  const [data, setData] = useState();

  const getFreeText = React.useCallback(() => {
    const apiUrl = `${baseUrl}${viewFreeText}${slug}`;
    axios
      .get(apiUrl, {
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      })
      .then((res) => {
        console.log("Data for free text is", res?.data);
        setData(res?.data?.data);
      })
      .catch((error) => {
        console.log("Error occured while getting free text.");
      });
  },[baseUrl,viewFreeText,slug,token]);

  React.useEffect(() => {
    getFreeText();
  }, [slug]);
  return (
    <>
      <Add_Free_Text data={data} />
    </>
  );
};

export default React.memo(Update_Free_Text);
