import { Button, TextField, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CommonMeta from "src/common/CommonMeta";
import Custom_BreadCrumbs from "src/common/Custom_BreadCrumbs";
import Header from "src/common/Header";
import Page_Title from "src/common/Page_Title";
import Autocomplete from "@mui/material/Autocomplete";
import { baseUrl, getAllSite, siteMapping } from "src/helpers/Config";
import axios from "axios";
import { notify } from "src/helpers/CommonToast";

const Map_Site = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const slug = location?.pathname?.replace(`/management/mapSite/`, "");
  const getApiData = `${baseUrl}${getAllSite}`;
  const breadcrumbs = [
    <Link
      key="1"
      color="inherit"
      to="/dashboards/dashboard"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Home
    </Link>,
    <Link
      key="2"
      color="inherit"
      to="/management/getAllUsers"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Users
    </Link>,
    <Link
      key="2"
      color="inherit"
      to={`/management/userProfile/${slug}`}
      style={{ textDecoration: "none", color: "gray" }}
    >
      User Profile
    </Link>,
    <Typography key="3" color="text.primary">
      Map Site
    </Typography>,
  ];
  const [siteData, setSiteData] = React.useState([]);
  const [mapSite, setMapSite] = React.useState({
    site_data:[]
  });
  const [loading, setLoading] = React.useState(false);
  const token = localStorage.getItem("token");

  const handleSiteChange = React.useCallback((event, newValue) => {
    const updatedSiteData = newValue.map((option) => ({
      name: option.name,
      url: option.url,
    }));
    setMapSite({
        ...mapSite,site_data:updatedSiteData
    });
  },[mapSite]);

  const getAllData = React.useCallback(async () => {
    await axios
      .get(`${getApiData}`, {
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      })
      .then((response) => {
        console.log("DATA FOR GET SITES", response.data.data);
        const actualData = response.data.data?.reverse();
        setSiteData(actualData);
      })
      .catch((error) => {
        console.log("Error occured in getting sites", error);
      });
  });

  const postSites = React.useCallback(() => {
    setLoading(true);
    const apiUrl = `${baseUrl}${siteMapping}${slug}`;
    axios
      .put(apiUrl, mapSite, {
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      })
      .then((res) => {
        console.log("Sites Mapped");
        notify("Sucessfully mapped site to the user","success")
        navigate(`/management/getAllUsers`)
      })
      .catch((err) => {
        console.log(`Site not mapped.`);
        notify("Error occured while mapping site to the user","error")
      })
      .finally(() => {
        setLoading(false);
      });
  },[baseUrl,siteMapping,slug]);

  React.useEffect(() => {
    getAllData();
  }, []);

  console.log("map site is", mapSite);
  return (
    <>
      <section style={{ margin: "auto", width: "95%" }}>
        <CommonMeta />
        <Custom_BreadCrumbs data={breadcrumbs} />
        <Page_Title>
          <Header title={"Map Site"} />
        </Page_Title>
        <form>
          <div className="mt-10">
            <Autocomplete
              fullWidth
              multiple
              id="tags-outlined"
              options={siteData}
              onChange={handleSiteChange}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              // getOptionSelected={(option, value) => option._id === value._id}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  sx={{ width: "100% !important" }}
                  {...params}
                  label="Add Site"
                  placeholder="Sites"
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-10">
            <Button variant="contained" disabled={loading} onClick={postSites}>
              {loading ? "Please wait..." : "Map site"}
            </Button>
          </div>
        </form>
      </section>
    </>
  );
};

export default React.memo(Map_Site);
