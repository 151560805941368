import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, useTheme } from '@mui/material';
import React from 'react';
const Scrollbar = ({ className, children, ...rest }) => {
  const theme = useTheme();
  return (
    <Scrollbars 
      autoHide
      renderThumbVertical={() => {
        return (
          <Box
            sx={{
              width: 5,
              height:'100%',
              background: `${theme.colors.alpha.black[10]}`,
              borderRadius: `${theme.general.borderRadiusLg}`,
              transition: `${theme.transitions.create(['background'])}`,

              '&:hover': {
                background: `${theme.colors.alpha.black[30]}`
              }
            }}
          />
        );
      }}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

export default React.memo(Scrollbar);
