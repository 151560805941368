import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Box, Typography, TextField } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { baseUrl, getUser, siteByUser } from "src/helpers/Config";
import { formattedLastLogin, style } from "./commonState";
import { Link } from "react-router-dom";

const CommonUserModal = ({ open, setOpen, data }) => {
  const handleClose = () => setOpen(false);
  const [userDetail, setUserDetail] = useState("");
  const [siteList, setSiteList] = useState([]);
  const token = localStorage.getItem(`token`);
  useEffect(() => {
    if (data) {
      const getUserData = `${baseUrl}${getUser}${data}`;
      axios
        .get(getUserData, {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        })
        .then((res) => {
          console.log("USER DETAILS ARE", res.data);
          setUserDetail(res.data?.data);
        })
        .catch((error) => {
          console.log("Error In USER DETAILS", error);
        });
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      const siteByUserApi = `${baseUrl}${siteByUser}${data}`;
      axios
        .get(siteByUserApi, {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        })
        .then((res) => {
          console.log("Sites of this user", res.data.data);
          setSiteList(res.data.data);
        })
        .catch((error) => {
          console.log("Error for USer Modal", error);
        });
    }
  }, [data]);
  const registerationStatus = (status) => {
    switch (status) {
      case 1:
        return `Completed`;
      case 0:
        return `Awaited`;
    }
  };

  const siteStatus = (status) => {
    switch (status) {
      case 0:
        return "Requested";
      case 1:
        return "Onboarded";
      case 2:
        return "Pending";
    }
  };

  return (
    <>
      <Modal
        open={open || false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            sx={{ gap: "20px" }}
          >
            <div style={{ maxHeight: "600px", overflow: "auto" }}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                <PersonOutlinedIcon /> User Detail
              </Typography>
              <div style={{ marginTop: "10px" }}>
                <div
                  class="container"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <div class="row">
                    <div id="no-more-tables">
                      <table
                        class="col-md-12 table-bordered table-striped table-condensed cf"
                        style={{ width: "500px" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col" class="tdAlign">
                              Name
                            </th>
                            <th class="tdAlign">{userDetail?.name}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row" class="tdAlign">
                              Email
                            </th>
                            <td class="tdAlign">{userDetail?.email}</td>
                          </tr>
                          <tr>
                            <th scope="row" class="tdAlign">
                              Registration Status
                            </th>
                            <td class="tdAlign">
                              {registerationStatus(userDetail?.status)}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" class="tdAlign">
                              Last logged in
                            </th>
                            <td class="tdAlign">
                              {formattedLastLogin(userDetail?.updated_at)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h2"
                  >
                    <VerifiedUserOutlinedIcon /> Site Details{" "}
                    <span>
                      <Link to="/management/userProfile">Click</Link>
                    </span>
                  </Typography>
                  <div style={{ marginTop: "10px", margin: "14px" }}>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                    >
                      Sites of this user:-
                    </Typography>
                    <div style={{ marginTop: "10px" }}>
                      {siteList?.length > 0 ? (
                        <table class="col-md-12 table-bordered table-striped table-condensed cf">
                          <thead>
                            <tr>
                              <th className="tdAlign">Name</th>
                              <th className="tdAlign">URL</th>
                              <th className="tdAlign">Site Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {siteList.map((item, index) => (
                              <tr key={index}>
                                <td className="tdAlign">{item.name}</td>
                                <td className="tdAlign">{item.url}</td>
                                <td className="tdAlign">
                                  {siteStatus(item.status)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p class="mt-3">No site added yet</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default React.memo(CommonUserModal);
