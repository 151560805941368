import React from "react";
import { TextField, Button, FormControl } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Header from "src/common/Header";
import { baseUrl, freeText, updateFreeText } from "src/helpers/Config";
import CommonMeta from "src/common/CommonMeta";
import { notify } from "src/helpers/CommonToast";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router";
import Custom_BreadCrumbs from "src/common/Custom_BreadCrumbs";
import { Link } from "react-router-dom";
import Page_Title from "src/common/Page_Title";

const Add_Free_Text = ({ data }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("login_data"))?.data?.authToken;
  const updateFreeTextApi = `${baseUrl}${updateFreeText}/${data?._id?.$oid}`;
  const [field, setField] = useState({
    target: "",
    keyword: "",
  });
  const breadcrumbs = [
    <Link
      key="1"
      color="inherit"
      to="/dashboards/dashboard"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Home
    </Link>,
    <Link
      key="2"
      color="inherit"
      to="/algorithm/freeText"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Algorithms
    </Link>,
    <Typography key="3" color="text.primary">
      Add Free Text
    </Typography>,
  ];

  const handleChange = React.useCallback(
    (e) => {
      const { name, value } = e.target;
      setField({ ...field, [name]: value });
    },
    [field]
  );

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      const headers = {
        accept: "*/*",
        "auth-Token": `${token}`,
      };

      data?.target
        ? axios
            .put(updateFreeTextApi, field, { headers })
            .then((res) => {
              console.log("Response data for updating free text is", res.data);
              if (res.data.status == true) {
                notify("Free text updated successfully.", "success");
                setField({
                  target: "",
                  keyword: "",
                });
                navigate(`/algorithm/freeText`);
                setLoading(false);
              } else {
                notify("Error occurred in updating free text.", "error");
                setLoading(false);
              }
            })
            .catch((error) => {
              console.log("Error occurred in updating data", error);
              notify("Error occurred in updating free text.", "error");
              setLoading(false);
            })
        : axios
            .post(`${baseUrl}${freeText}`, field, { headers })
            .then((res) => {
              console.log("Response data for adding free text is", res.data);
              if (res.data.status == true) {
                notify("Free text added successfully.", "success");
                setField({
                  target: "",
                  keyword: "",
                });
                navigate(`/algorithm/freeText`);
                setLoading(false);
              } else {
                notify("Error occurred in adding free text.", "error");
                setLoading(false);
              }
            })
            .catch((error) => {
              console.log("Error occurred in adding data", error);
              notify("Error occurred in adding free text.", "error");
              setLoading(false);
            });
    },
    [token, field]
  );

  const [targetOptions, setTargetOptions] = React.useState([
    "About us",
    "Contact us",
  ]);
  const filterOptions = (options, params) => {
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(params.inputValue.toLowerCase())
    );

    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option);
    if (inputValue !== "" && !isExisting) {
      filtered.push(inputValue);
    }

    return filtered;
  };

  React.useEffect(() => {
    if (data?.target) {
      setField({
        target: data?.target || "",
        keyword: data?.keyword || "",
      });
      setTargetOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        if (data.target && !updatedOptions.includes(data.target)) {
          updatedOptions.push(data.target);
        }
        return updatedOptions;
      });
    }
  }, [data]);

  console.log(targetOptions, "targetOptions is");
  return (
    <>
      <section style={{ width: "95%",margin:'auto' }}>
        <CommonMeta />
        <Custom_BreadCrumbs data={breadcrumbs} />
        <Page_Title>
          <Header title={data ? "Update Free text" : "Add Free text"} />
        </Page_Title>
        <form onSubmit={handleSubmit}>
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                margin: "auto",
                gap: "10px",
                marginTop: "15px",
              }}
            >
              <FormControl style={{ width: "50%" }}>
                <Autocomplete
                  value={field?.target}
                  onChange={(event, newValue) => {
                    setField({
                      ...field,
                      target: newValue,
                    });
                  }}
                  filterOptions={filterOptions}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="target"
                  options={targetOptions}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  sx={{ width: "100%" }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Target" />
                  )}
                />
              </FormControl>
              <TextField
                id="outlined-basic"
                label="Keyword"
                variant="outlined"
                name="keyword"
                style={{ width: "50%" }}
                value={field.keyword}
                onChange={handleChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Please wait..." : `Submit`}
              </Button>
            </div>
          </>
        </form>
      </section>
    </>
  );
};

export default React.memo(Add_Free_Text);
