import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import SidebarLayout from "src/layouts/SidebarLayout";
import SuspenseLoader from "src/components/SuspenseLoader";
import GetAll_Sites from "../nic_components/Sites/Site Details/GetAll_Sites";
import Create_Site from "../nic_components/Sites/Site Details/Create_Site";
import Create_User from "../nic_components/Management/Users/Create_User";
import Sites_Requesting from "../nic_components/Sites/Site Details/Sites_Requesting";
import Detailed_Site from "../nic_components/Sites/Site Details/Detailed_Site";
import Update_Free_Text from "src/nic_components/Algorithm/Update_Free_Text";
import Map_Site from "src/nic_components/Management/Users/Map_Site";

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Detailed_User = Loader(lazy(() => import("../nic_components/Management/Users/Detailed_User")));
const Dashboard_Main = Loader(lazy(() => import("../nic_components/Dashboard/Dashboard_Main")),);
const Activity = Loader(lazy(() => import("../nic_components/Dashboard/Activity")));
const Login_Page = Loader(lazy(() => import("src/auth/Login")));
const Status404 = Loader(lazy(() => import("src/content/pages/Status/Status404")));
const Manual_Report_Table = Loader(lazy(() => import(`../nic_components/Configuration/Manual_Report_Table`)));
const Edit_Manual_Report = Loader(lazy(() => import(`../nic_components/Configuration/Edit_Manual_Report`)));
const Manual_Report = Loader(lazy(() => import(`../nic_components/Configuration/Manual_Report`)));
const UpdateAdmin = Loader(lazy(() => import(`../nic_components/Management/Administration/UpdateAdmin`)));
const CreateAdmin = Loader(lazy(() => import(`../nic_components/Management/Administration/CreateAdmin`)));
const Historic_Queue = Loader(lazy(() => import(`../nic_components/Monitoring/Historic_Queue`)));
const Current_Queue = Loader(lazy(() => import(`../nic_components/Monitoring/Current_Queue`)));
const GetAll_Users = Loader(lazy(() => import(`../nic_components/Management/Users/GetAll_Users`)));
const Framework = Loader(lazy(() => import(`../nic_components/Configuration/Framework`)));
const Download_Reports = Loader(lazy(() => import(`../nic_components/Sites/Site Details/Download_Reports`)));
const User_Queue_List = Loader(lazy(() => import(`../nic_components/Sites/Site Details/User_Queue_List`)));
const Add_Setting = Loader(lazy(() => import(`../nic_components/Configuration/Add_Setting`)));
const Add_Free_Text = Loader(lazy(() => import(`../nic_components/Algorithm/Add_Free_Text`)));
const Free_Text = Loader(lazy(() => import(`../nic_components/Algorithm/Free_text`)));
const Bulk_Upload = Loader(lazy(() => import(`../nic_components/Sites/Bulk WIM/Bulk_Upload`)));
const Admin = Loader(lazy(() => import(`../nic_components/Management/Administration/Admin`)));

const routes = [
  {
    path: "",
    element: <Login_Page />,
    children: [
      {
        path: "/",
        element: <Login_Page />,
      },
      {
        path: "overview",
        element: <Navigate to="/" replace />,
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
  {
    path: "dashboards",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: "dashboard",
        element: <Dashboard_Main />,
      },
      {
        path: "activity",
        element: <Activity />,
      },
    ],
  },
  {
    path: "management",
    element: <SidebarLayout />,
    children: [
      {
        path: "createSite",
        element: <Create_Site />,
      },
      {
        path: "mapSite/:id",
        element: <Map_Site />,
      },
      {
        path: "getAllSites",
        element: <GetAll_Sites />,
      },
      {
        path: "getAllUsers",
        element: <GetAll_Users />,
      },
      {
        path: "userProfile/:id",
        element: <Detailed_User />,
      },
      {
        path: "createUser",
        element: <Create_User />,
      },
      {
        path: "createManualUser",
        element: <Create_User />,
      },
    ],
  },
  {
    path: "site-management",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="getAllOnboardedSite" replace />,
      },
      {
        path: "getAllOnboardedSite",
        element: <GetAll_Sites />,
      },
      {
        path: "getRequestedSite",
        element: <Sites_Requesting />,
      },
      {
        path: "currentQueueSites",
        element: <Current_Queue />,
      },
      {
        path: "hisoricalQueueSites",
        element: <Historic_Queue />,
      },
      {
        path: "userQueueList/:id",
        element: <User_Queue_List />,
      },
      {
        path: "downloadReports/:id",
        element: <Download_Reports />,
      },
      {
        path: "bulkUpload",
        element: <Bulk_Upload />,
      },
      {
        path: "siteDetails/:id",
        element: <Detailed_Site />,
      },
    ],
  },
  {
    path: "admin-management",
    element: <SidebarLayout />,
    children: [
      {
        path: "admin",
        element: <Admin />,
      },
      {
        path: "createAdmin",
        element: <CreateAdmin />,
      },
      {
        path: "updateAdmin/:id",
        element: <UpdateAdmin />,
      },
    ],
  },
  {
    path: "algorithm",
    element: <SidebarLayout />,
    children: [
      {
        path: "freeText",
        element: <Free_Text />,
      },
      {
        path: "addfreeText",
        element: <Add_Free_Text />,
      },
      {
        path: "updateFreeText/:id",
        element: <Update_Free_Text />,
      },
    ],
  },
  {
    path: "configuration",
    element: <SidebarLayout />,
    children: [
      {
        path: "addSetting",
        element: <Add_Setting />,
      },
      {
        path: "framework",
        element: <Framework />,
      },
      {
        path: "manualReportTable",
        element: <Manual_Report_Table />,
      },
      {
        path: "manualWebsiteReport/:id",
        element: <Manual_Report />,
      },
      {
        path: "editReport/:id",
        element: <Edit_Manual_Report />,
      },
    ],
  },
];

export default routes;
