import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Header from "src/common/Header";
import {
  baseUrl,
  createSite,
  getRegisteredEmails,
  updateSite,
} from "src/helpers/Config";
import CommonMeta from "src/common/CommonMeta";
import { notify } from "src/helpers/CommonToast";
import Custom_BreadCrumbs from "src/common/Custom_BreadCrumbs";
import Ministry from "src/nic_components/common/Ministry";
import Page_Title from "src/common/Page_Title";

const Create_Site = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const data = location.state;
  const update_Site_Url = `${baseUrl}${updateSite}`;
  const add_Site_Url = `${baseUrl}${createSite}`;
  const [siteLoad, setSiteLoad] = React.useState(false);
  const [autoSuggestion, setAutoSuggestion] = useState([]);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [departmentData, setDepartmentData] = useState([]);
  const breadcrumbs = [
    <Link
      key="1"
      color="inherit"
      to="/dashboards/dashboard"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Home
    </Link>,
    <Link
      key="2"
      color="inherit"
      to="/site-management/getAllOnboardedSite"
      style={{ textDecoration: "none", color: "gray" }}
    >
      Sites
    </Link>,
    <Typography key="3" color="text.primary">
      {data ? "Update Site" : "Create Site"}
    </Typography>,
  ];

  const [field, setField] = useState({
    name: data?.name || "",
    url: data?.url || "",
    _id: data?._id,
    description: data?.description || "",
    logo_url: data?.logo_url || "",
    user_Email: data?.user_Email || "",
    user_Name: data?.user_Name || "",
    ministry: data?.ministry || "",
    departments: data?.departments || "",
    user_id: data?.user_id || "",
  });
  const [error, setError] = useState({
    nameError: false,
    urlError: false,
    descriptionError: false,
    logoUrlError: false,
    userEmailError: false,
    userNameError: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setField((prevField) => ({
      ...prevField,
      [name]: value,
    }));

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setError((prevError) => ({
      ...prevError,
      nameError: name === "name" && value.length < 3,
      urlError: name === "url" && !urlRegex.test(value),
      descriptionError: name === "description" && value.length === 0,
      logoUrlError: name === "logo_url" && value.length === 0,
      userEmailError: name === "user_Email" && !emailRegex.test(value),
      userNameError: name === "user_Name" && value.length === 0,
    }));
  };
  const updateData = () => {
    setSiteLoad(true);
    const formData = new FormData();
    formData.append("name", field.name);
    formData.append("url", field.url);
    formData.append("logo_url", field.logo_url);
    formData.append("description", field.description);
    formData.append("user_Name", field.user_Name);
    formData.append("user_Email", field.user_Email);
    formData.append("ministry", field.ministry);
    formData.append("department", field.departments);
    {
      data?.name
        ? axios
            .put(`${update_Site_Url}${data._id}`, field, {
              headers: {
                accept: "*/*",
                "Auth-Token": `${token}`,
              },
            })
            .then((res) => {
              console.log("Successfully Updated", res.data);
              notify("Site updated sucessfully.", "success");
              navigate(`/site-management/getAllOnboardedSite`);
            })
            .catch((error) => {
              notify(`Error occurred while updating users`, `error`);
              console.log("Error occurred in updating the user data", error);
              notify("Error occured in updating site.", "error");
            })
            .finally(() => {
              setSiteLoad(false);
            })
        : axios
            .post(`${add_Site_Url}`, formData, {
              headers: {
                accept: "*/*",
                "Auth-Token": `${token}`,
              },
            })
            .then((res) => {
              console.log("Successfully registered", res.data);
              const error = res?.data?.error?.url[0];
              console.log("Reachedddd", error);
              // alert("Reachedddd",JSON.stringify(error))
              if (error?.length > 0) {
                notify(`The site url is already been taken`, `error`);
              } else {
                notify("Site added sucessfully.", "success");
                navigate(`/site-management/getAllOnboardedSite`);
              }
            })
            .catch((error) => {
              console.log("Error occurred while adding users", error);
              notify("Error occured in adding site.", "error");
            })
            .finally(() => {
              setSiteLoad(false);
            });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateData();
  };

  const getSuggestion = React.useCallback(() => {
    setSuggestionLoading(true);
    axios
      .post(
        `${baseUrl}${getRegisteredEmails}`,
        { email: field.user_Email },
        {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        }
      )
      .then((res) => {
        console.log("Data for the response auto suggestion is", res.data);
        setAutoSuggestion(res?.data?.data);
        setSuggestionLoading(false);
      })
      .catch((error) => {
        console.log(
          "Error occurred while getting email auto suggestion",
          error
        );
        setSuggestionLoading(false);
      });
  }, [baseUrl, getRegisteredEmails, field.user_Email, token]);

  const handleGetDataFromSuggestion = (item) => {
    setField({
      ...field,
      ["user_Email"]: item.email,
      ["user_Name"]: item.name,
    });
  };

  React.useEffect(() => {
    if (departmentData?.length > 0 && data?.departments) {
      setField({
        ...field,
        departments: data?.departments,
      });
    }
  }, [departmentData, data]);

  useEffect(() => {
    if (field?.user_Email?.length > 3 && showSuggestion) {
      getSuggestion();
    } else {
      setAutoSuggestion([]);
    }
  }, [field?.user_Email]);

  console.log("Data is", data);

  return (
    <>
      <section style={{ margin: "auto", width: "95%" }}>
        <CommonMeta />
        <Custom_BreadCrumbs data={breadcrumbs} />
        <Page_Title>
          <Header title={data ? "Update Site" : "Create Site"} />
        </Page_Title>
        <div className="tab-content mt-2">
          <div
            className="tab-pane fade show active"
            id="content1"
            style={{
              background: "#fff",
              width: "100%",
              borderRadius: "20px",
              boxShadow: "0 0 52px #3d3d3d1f",
              border: "1px solid #dcdcdc",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Card>
                <CardHeader title={data ? "Update Site" : "Create Site"} />
                <Divider />
                <Box
                  component="form"
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    width: "100%",
                    marginTop: "100px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    padding: "1rem",
                    margin: "0px",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
                    <TextField
                      id="standard-multiline-flexible"
                      placeholder="Enter your Site name"
                      label="Site Name"
                      multiline
                      name="name"
                      value={field.name}
                      maxRows={4}
                      variant="outlined"
                      sx={{ flex: 1 }}
                      onChange={handleChange}
                      error={error.nameError}
                      helperText={
                        error.nameError
                          ? "Name must be at least 3 characters long"
                          : ""
                      }
                    />
                    <TextField
                      id="standard-textarea"
                      label="Site Url"
                      placeholder="Enter your Site Url"
                      multiline
                      name="url"
                      disabled={data?.url ? true : false}
                      value={field.url}
                      variant="outlined"
                      sx={{ flex: 1 }}
                      onChange={handleChange}
                      error={error.urlError}
                      helperText={
                        error.urlError ? "Please enter valid url" : ""
                      }
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
                    <TextField
                      id="standard-textarea"
                      label="Site Description"
                      placeholder="Enter your Site Description"
                      multiline
                      name="description"
                      value={field?.description}
                      variant="outlined"
                      sx={{ flex: 1 }}
                      onChange={handleChange}
                      error={error.descriptionError}
                      helperText={
                        error.descriptionError ? "Please enter description" : ""
                      }
                    />

                    <TextField
                      id="standard-textarea"
                      label="Logo url"
                      placeholder="Enter your website logo url"
                      multiline
                      name="logo_url"
                      value={field?.logo_url}
                      variant="outlined"
                      sx={{ flex: 1 }}
                      onChange={handleChange}
                      error={error.logoUrlError}
                      helperText={
                        error.logoUrlError ? "Please enter some value" : ""
                      }
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
                    <Ministry
                      data={data?.ministry}
                      departmentField={field?.departments}
                      ministryValue={field?.ministry}
                      onChange={handleChange}
                      departmentData={departmentData}
                      setDepartmentData={setDepartmentData}
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
                    <div style={{ position: "relative", width: "50%" }}>
                      <Autocomplete
                        id="user-email-autocomplete"
                        options={autoSuggestion}
                        getOptionLabel={(item) => item?.email}
                        loading={suggestionLoading}
                        onChange={(event, value) => {
                          handleGetDataFromSuggestion(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="standard-textarea"
                            label="User email"
                            placeholder="Enter your user email"
                            multiline
                            name="user_Email"
                            disabled={data?.user_Email ? true : false}
                            value={
                              data?.user_Email
                                ? data?.user_Email
                                : field?.user_Email
                            }
                            variant="outlined"
                            sx={{ flex: 1, width: "100%" }}
                            onChange={handleChange}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {suggestionLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                    <div style={{ position: "relative", width: "50%" }}>
                      <TextField
                        id="standard-textarea"
                        label="User name"
                        placeholder="Enter username"
                        multiline
                        name="user_Name"
                        value={
                          data?.user_Name ? data?.user_Name : field?.user_Name
                        }
                        disabled={data?.user_Name ? true : false}
                        variant="outlined"
                        sx={{ flex: 1, width: "100%" }}
                        onChange={handleChange}
                      />
                    </div>
                  </Box>
                  <Box>
                    {data ? (
                      <Button
                        variant="contained"
                        sx={{ margin: "30px" }}
                        disabled={
                          !field.url ||
                          !field.name ||
                          !field.logo_url ||
                          !field.description ||
                          !field.user_Email ||
                          !field.user_Name ||
                          error.descriptionError ||
                          error.logoUrlError ||
                          error.nameError ||
                          error.urlError ||
                          siteLoad
                        }
                        onClick={handleSubmit}
                      >
                        {siteLoad ? `Please wait...` : `Update Site`}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ margin: "30px" }}
                        onClick={handleSubmit}
                        disabled={
                          !field.url ||
                          !field.name ||
                          !field.logo_url ||
                          !field.description ||
                          !field.user_Email ||
                          !field.user_Name ||
                          error.descriptionError ||
                          error.logoUrlError ||
                          error.nameError ||
                          error.urlError ||
                          siteLoad
                        }
                      >
                        {siteLoad ? `Please wait...` : `Add Site`}
                      </Button>
                    )}
                  </Box>
                </Box>
              </Card>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Create_Site);
