import { Checkbox, IconButton, Tooltip } from '@mui/material'
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const CustomView = React.memo(({title,action}) =>{
    return <>
     <Tooltip title={title} arrow onClick={action}>
          <IconButton color="inherit" size="small">
          <RemoveRedEyeOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
    </>
})

export const CustomEdit = React.memo(({title,action}) =>{
    return <>
     <Tooltip title={title} arrow onClick={action}>
          <IconButton color="inherit" size="small">
            <EditTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
    </>
})

export const CustomDelete = React.memo(({title,action}) =>{
    return <>
     <Tooltip title={title} arrow onClick={action}>
          <IconButton color="inherit" size="small">
          <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
    </>
})

export const CustomApprove = React.memo(({title,action}) =>{
  return <>
   <Tooltip title={title} arrow onClick={action}>
        <IconButton color="success" size="small">
        <CheckIcon fontSize="small" />
        </IconButton>
      </Tooltip>
  </>
})

export const CustomCheckbox = React.memo(({title,onChange,checked}) =>{
    return <>
     <Tooltip title={title} arrow>
          <Checkbox
            {...label}
            color="success"
            checked={checked}
            onChange={onChange}
          />
        </Tooltip>
    </>
})
