import React, { useState } from "react";
import axios from "axios";
import { notify } from "src/helpers/CommonToast";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Chip } from "@mui/material";

const DeleteDialogue = ({
  title,
  openDialogue,
  setopenDialogue,
  setAnswer,
  apiUrl,
  id,
  getAllData,
}) => {
  const token = localStorage.getItem("token") || "";
  const [loading, setLoading] = useState();
  const config = {
    headers: {
      accept: "*/*",
      "Auth-Token": `${token}`,
    },
  };
  const handleClose = (response) => {
    if (response == true) {
      setLoading(true);
      axios
        .delete(`${apiUrl}${id}`, config)
        .then((res) => {
          getAllData();
          notify(`${title} deleted successfully.`, "success");
          console.log("Data Deleted Successfully", res.data);
        })
        .catch((error) => {
          notify(`${title} deleted successfully.`, "success");
          console.log("Error occurred in deleting the data", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setopenDialogue(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openDialogue || false}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to delete this ${title} ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`This action will soft delete the ${title}, meaning the data will be
            retained but marked for deletion.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Chip
            label={`Cancel`}
            color="success"
            onClick={() => handleClose(false)}
          />
          <Chip
            disabled={loading}
            autoFocus
            label={`Delete`}
            color="error"
            onClick={() => handleClose(true)}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default React.memo(DeleteDialogue);
